import nookies, { parseCookies } from 'nookies';

const localCache = {};

export function getCookie(name, { context, req, cookies } = {}) {
  if (!context && !req && !cookies && name in localCache) {
    return localCache[name];
  }
  const reqCookies =
    cookies || (context ? nookies.get(context) : parseCookies(req ? { req } : undefined));
  return reqCookies[name];
}

export function setCookie(name, value, ctx = null) {
  localCache[name] = value;
  nookies.set(ctx, name, value ? value.toString() : '', {
    maxAge: 12 * 60 * 60, // half day
    path: '/',
  });
}
