const CENSOR_REPLACEMENT = 'censored';

export default class APIError extends Error {
  constructor(message, status, data) {
    super(message);
    this.status = status;
    this.data = data;

    // Ensure potentially sensitive data isnt logged
    // TODO: probably need to expand upon this
    if (this.data && this.data.password !== 'undefined') {
      this.data.password = CENSOR_REPLACEMENT;
    }
  }
}
