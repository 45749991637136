import nookies, { parseCookies } from 'nookies';

export default function getIsTestmode({ context, req, cookies } = {}) {
  const reqCookies =
    cookies || (context ? nookies.get(context) : parseCookies(req ? { req } : undefined));
  return reqCookies.isProdmode !== 'true';
}

export function setIsTestmode(value) {
  nookies.set(null, 'isProdmode', (!value).toString(), {
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
  });
}

export function switchTestModeClient() {
  const ele = document.getElementById('testmode-switch');
  if (ele) {
    ele.click();
  }
}
