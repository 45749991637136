import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

import useApi from '../utils/api';

const MAX_PER_PAGE = 16;

export const NotificationContext = createContext();

export const useNotifications = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const { apiGet, apiPost } = useApi();
  const { data: session } = useSession();
  const router = useRouter();

  const fetchNotifications = async () => {
    // TODO: better check to see if api key exists?
    if (
      !session ||
      router.pathname.startsWith('/onboarding') ||
      router.pathname.startsWith('/_offline')
    ) {
      return;
    }

    try {
      const data = await apiGet(`data/notifications?limit=${MAX_PER_PAGE}`);
      setNotifications(data.list);
      setUnreadCount(data.unreadTotal);
      setTotalCount(data.total);
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    }
  };

  const fetchNotificationsNext = async (offset) => {
    try {
      const data = await apiGet(
        `data/notifications?limit=${MAX_PER_PAGE}&offset=${offset || notifications.length}`
      );
      setNotifications([...notifications, ...data.list]);
      setUnreadCount(data.unreadTotal);
      setTotalCount(data.total);
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    }
  };

  const markAllAsRead = async () => {
    try {
      await apiPost('data/notifications/mark-read');

      // Update local cache after marking as read
      if (notifications.length) {
        const updatedNotifications = notifications.map((n) => ({ ...n, read: true }));
        setNotifications(updatedNotifications);
      }
      setUnreadCount(0);
    } catch (error) {
      console.error('Failed to mark notifications as read:', error);
    }
  };

  useEffect(() => {
    // Setting up polling every 5 minutes (300000ms)
    const intervalId = setInterval(fetchNotifications, 300000);
    return () => clearInterval(intervalId);
  }, []);

  // Fetch notifications when session refreshes
  useEffect(() => {
    if (session) {
      fetchNotifications();
    }
  }, [session]);

  const value = {
    notifications,
    unreadCount,
    fetchNotifications,
    markAllAsRead,
    totalCount,
    fetchNotificationsNext,
  };

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export const useNotificationData = () => {
  const {
    notifications,
    unreadCount,
    fetchNotifications,
    fetchNotificationsNext,
    markAllAsRead,
    totalCount,
  } = useNotifications();

  return {
    notifications,
    unreadCount,
    refreshNotifications: fetchNotifications,
    fetchNotificationsNext,
    markAllAsRead,
    totalCount,
  };
};
