import { useContext, useCallback, useEffect, useRef } from 'react';

import ModalContext from './modal-context';
import { uid } from './utils';

export default function useModal(optionsRoot = {}) {
  const {
    showModal,
    destroyModalsByRootId: destroy,
    ...otherContextProps
  } = useContext(ModalContext);
  const id = useRef(uid(6));

  useEffect(
    () => () => {
      destroy(id.current);
    },
    [destroy]
  );

  return {
    showModal: useCallback(
      (component, props, options) =>
        showModal(component, props, { rootId: id.current, ...options, ...optionsRoot }),
      [showModal, optionsRoot]
    ),
    showModalWait: useCallback(
      (component, props, options) => {
        const prom = new Promise((resolve) => {
          showModal(
            component,
            {
              ...props,
              onClose: (val) => {
                if (props.onClose) {
                  props.onClose(val);
                }
                resolve(val);
              },
            },
            { rootId: id.current, ...options, ...optionsRoot }
          );
        });
        return prom;
      },
      [showModal, optionsRoot]
    ),
    ...otherContextProps,
  };
}
