import React, { Children } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

const ActiveLink = ({ children, activeClassName, activeSx, ...props }) => {
  const { asPath } = useRouter();
  const child = Children.only(children);
  const childClassName = child.props.className || '';
  const childPropsSx = child.props.style || {};

  // pages/index.js will be matched via props.href
  // pages/about.js will be matched via props.href
  // pages/[slug].js will be matched via props.as
  const className =
    asPath === props.href || asPath === props.as
      ? `${childClassName} ${activeClassName}`.trim()
      : childClassName;

  const childSx =
    asPath === props.href || asPath === props.as ? { ...childPropsSx, ...activeSx } : childPropsSx;

  return (
    <Link legacyBehavior {...props}>
      {React.cloneElement(child, {
        className: className || null,
        style: childSx,
      })}
    </Link>
  );
};

export default ActiveLink;
