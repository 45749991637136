import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { Typography, Button } from '@mui/material';
import { useRouter } from 'next/router';

import Modal from './modal';
import getIsTestmode, { setIsTestmode } from '../utils/testmode';
import WarningSVG from '../../public/icons/dialog-warning.svg';

export default function UpgradeDialogModal({
  title = 'Select a plan to access this feature',
  text = 'To access this feature, please choose a plan',
  open,
  onClose,
  disableClose,
  content,
  children,
  hideIcon = false,
  icon = <WarningSVG style={{ flexShrink: 0, marginRight: '12px' }} />,
  textContactLink = 'https://dock.io/contact',
  ctaContactLink = 'https://www.dock.io/contact-sales',
  displayTestmodeSuggestion = true,
}) {
  const router = useRouter();
  const showIcon = !hideIcon && icon;
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(open);
  const shouldDisableCloseButton = disableClose || getIsTestmode();

  useEffect(() => {
    setUpgradeModalOpen(true || open);
  }, [open]);

  const handleUpgradeModalClose = async () => {
    setUpgradeModalOpen(false);
    setIsTestmode(true);
    await router.replace(router.asPath);

    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      open={upgradeModalOpen && open}
      onClose={shouldDisableCloseButton ? null : handleUpgradeModalClose}
      style={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '400px',
          },
        },
      }}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        {showIcon && icon}

        <Typography
          variant="h6"
          gutterBottom
          style={{ display: 'flex', fontWeight: 500, marginTop: '20px' }}>
          {title}
        </Typography>

        {text && (
          <Typography variant="body2">
            {text}. If you need any help or have any questions, please{' '}
            <u>
              <a href={textContactLink} target="_blank" rel="noreferrer">
                contact our support team
              </a>
            </u>
            .
            <br />
            {displayTestmodeSuggestion && (
              <>
                <br />
                Alternatively, you can enable test mode to try out some features on our test
                network.
              </>
            )}
          </Typography>
        )}

        {content}
        {children}

        <div style={{ display: 'flex', width: '100%', marginTop: '32px', gap: '14px' }}>
          <Button
            component="a"
            href={ctaContactLink}
            target="_blank"
            rel="noreferrer"
            disableElevation
            variant="contained"
            color="secondary"
            sx={{ width: '100%' }}
            onClick={onClose}>
            Contact sales
          </Button>
          <Link legacyBehavior style={{ flex: 1 }} href="/plans" passHref>
            <Button
              component="a"
              disableElevation
              variant="contained"
              color="primary"
              sx={{ width: '100%' }}
              onClick={onClose}>
              View plans
            </Button>
          </Link>
        </div>
      </div>
    </Modal>
  );
}
