import React from 'react';

import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { useMediaQuery, useTheme } from '@mui/material';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function DialogTitle(props) {
  const { sx, children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      sx={{
        margin: 0,
        padding: (theme) => theme.spacing(4),
        paddingBottom: 0,
        ...sx,
      }}
      {...other}>
      <Typography variant={props.variant || 'h5'}>{children}</Typography>
      {onClose ? (
        <IconButton
          id="close-modal-btn"
          data-intercom-target="close-modal-btn"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(1),
            top: (theme) => theme.spacing(1),
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

export default function Modal({
  children,
  title = '',
  actions,
  onClose,
  style,
  titleVariant = 'h5',
  contentProps = {},
  fullScreenBreakpoint = 'sm',
  open = true,
  fullScreen = false,
  showCloseIcon = true,
  fullWidth = true,
  maxWidth = 'md',
  actionsSx = {},
  titleSx = {},
  contentPadding = '24px 24px 24px 24px',
}) {
  const themet = useTheme();
  const fullScreenOverride = useMediaQuery(themet.breakpoints.down(fullScreenBreakpoint));

  const titleStyles =
    typeof title === 'string'
      ? {}
      : titleSx || {
          padding: '10px 15px',
          height: '56px',
        };

  return (
    <Dialog
      onClose={onClose}
      fullScreen={fullScreenOverride || fullScreen}
      TransitionComponent={Transition}
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      sx={style}>
      {title && (
        <DialogTitle
          onClose={showCloseIcon ? onClose : undefined}
          variant={titleVariant}
          sx={titleStyles}>
          {title}
        </DialogTitle>
      )}
      {children && (
        <MuiDialogContent
          dividers
          {...contentProps}
          sx={{
            borderTop: 'none',
            borderBottom: 'none',
            padding: contentPadding,
          }}>
          {!title && onClose && showCloseIcon && (
            <IconButton
              id="close-modal-btn"
              data-intercom-target="close-modal-btn"
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: (theme) => theme.spacing(1),
                top: (theme) => theme.spacing(1),
                color: (theme) => theme.palette.grey[500],
              }}>
              <CloseIcon />
            </IconButton>
          )}
          {children}
        </MuiDialogContent>
      )}
      {actions && (
        <MuiDialogActions
          sx={{
            margin: 0,
            padding: (theme) => theme.spacing(2),
            paddingRight: (theme) => theme.spacing(3),
            paddingLeft: (theme) => theme.spacing(3),
            ...actionsSx,
          }}>
          {actions}
        </MuiDialogActions>
      )}
    </Dialog>
  );
}
