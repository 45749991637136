import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { ListItem, Menu, MenuItem, Typography } from '@mui/material';
import Router from 'next/router';

import Notification from './notification';

export default function NotificationsMenu({ anchorEl, open, onClose, notifications }) {
  const { t } = useTranslation('common');

  function handleViewAll() {
    onClose();
    Router.push('/notifications');
  }

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={onClose}
      dense
      MenuListProps={{
        sx: {
          width: '100%',
        },
      }}
      PaperProps={{
        sx: {
          minWidth: 480,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      }}>
      <MenuItem
        sx={{
          pointerEvents: 'none',
          '&.Mui-focusVisible': { backgroundColor: '#ffffff!important' },
        }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '30px',
          }}>
          {t('notifications-menu-title')}
        </Typography>
      </MenuItem>

      {notifications &&
        (notifications.length === 0 ? (
          <ListItem alignItems="flex-start">{t('notifications-menu-empty')}</ListItem>
        ) : (
          notifications
            .slice(0, 4)
            .map((notification) => <Notification key={notification.id} {...notification} />)
        ))}

      <MenuItem sx={{ width: '100%', paddingTop: '10px' }} onClick={handleViewAll}>
        <Typography
          variant="h6"
          sx={{
            width: '100%',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            color: '#004EEB',
            textAlign: 'right',
          }}>
          {t('notifications-menu-view-all')}
        </Typography>
      </MenuItem>
    </Menu>
  );
}
