import React, { useState, useEffect } from 'react';
import { useSession } from 'next-auth/react';
import fetch from 'isomorphic-unfetch';
import * as Sentry from '@sentry/nextjs';
import { TextField, Typography, Button } from '@mui/material';
import { useSnackbar } from '../hooks/snackbar';
import Modal from './modal';

function validateEmail(email) {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
}

export default function ContactUsModal({ open, onClose, title, message }) {
  const { data: session } = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [userData, setUserData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (session && (!userData.name || !userData.email)) {
      setUserData({
        name: session.user.name,
        email: session.user.email,
        message,
        title,
      });
    }
  }, [session]);

  async function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);

    if (!userData.name) {
      enqueueSnackbar('Name is required', {
        variant: 'error',
      });
      return;
    }

    if (!userData.message) {
      enqueueSnackbar('Message is required', {
        variant: 'error',
      });
      return;
    }

    if (!userData.email || !validateEmail(userData.email)) {
      enqueueSnackbar('Please enter a valid email address.', {
        variant: 'error',
      });
      return;
    }

    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const { error } = await response.json();
        throw new Error(error || 'Unknown error');
      }

      setUserData({
        name: session && session.user && session.user.name,
        email: session && session.user && session.user.email,
      });
      onClose();
      enqueueSnackbar('Thanks! Your message has been submitted.', {
        variant: 'success',
      });
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(`Unable to send message: ${error.message}`, {
        variant: 'error',
      });
    }

    setIsSubmitting(false);
  }

  function handleChangeField(e) {
    const newData = {};
    newData[e.target.name] = e.target.value;
    setUserData({
      ...userData,
      ...newData,
    });
  }

  return (
    <Modal title={title || 'Contact us'} {...{ open, onClose }}>
      <Typography variant="body2">
        Fill in the below form to contact the Dock team with your query.
      </Typography>

      <br />

      <form onSubmit={handleSubmit}>
        <TextField
          margin="dense"
          variant="outlined"
          name="name"
          label="Name"
          placeholder="Your Name"
          fullWidth
          onChange={handleChangeField}
          value={userData.name}
          required
        />

        <TextField
          margin="dense"
          variant="outlined"
          type="email"
          name="email"
          label="Email"
          placeholder="youremail@service.com"
          fullWidth
          disabled={!!session}
          onChange={handleChangeField}
          value={userData.email}
          required
        />

        <TextField
          margin="dense"
          variant="outlined"
          name="message"
          label="Message"
          placeholder="Your message goes here..."
          fullWidth
          multiline
          rows={5}
          onChange={handleChangeField}
          value={userData.message}
          required
        />

        <br />
        <br />

        <Button
          disabled={isSubmitting}
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          disableElevation
          fullWidth>
          Submit
        </Button>
      </form>
    </Modal>
  );
}
