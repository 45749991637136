import React, { useReducer } from 'react';
import ModalContext from './modal-context';
import reducer, { initialState, Types } from './reducer';
import { uid } from './utils';

export default function ModalProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const hide = React.useCallback(
    (id) => {
      if (!id) {
        return;
      }

      dispatch({
        type: Types.HIDE,
        payload: {
          id,
        },
      });
    },
    [dispatch]
  );

  const destroy = React.useCallback(
    (id) => {
      if (!id) {
        return;
      }

      dispatch({
        type: Types.DESTROY,
        payload: {
          id,
        },
      });
    },
    [dispatch]
  );

  const destroyByRootId = React.useCallback(
    (rootId) => {
      if (!rootId) {
        return;
      }

      dispatch({
        type: Types.DESTROY_BY_ROOT_ID,
        payload: {
          rootId,
        },
      });
    },
    [dispatch]
  );

  const show = React.useCallback(
    (component, props, options) => {
      let id = uid(8);

      if (options && options.rootId) {
        id = `${options.rootId}.${id}`;
      }

      dispatch({
        type: Types.SHOW,
        payload: {
          id,
          component,
          props,
          options,
        },
      });

      return {
        id,
        hide: () => hide(id),
        destroy: () => destroy(id),
      };
    },
    [dispatch, hide, destroy]
  );

  const renderState = () =>
    Object.keys(state).map((id) => {
      const { component: Component, props, options } = state[id];

      const handleClose = (...args) => {
        if (options && options.destroyOnClose) {
          destroy(id);
        } else {
          hide(id);
        }

        if (props && props.onClose) {
          props.onClose(...args);
        }
      };

      const handleExited = (...args) => {
        if (props?.onExited) {
          props.onExited(...args);
        }

        if (props?.TransitionProps?.onExited) {
          props.TransitionProps.onExited(...args);
        }

        destroy(id);
      };

      let extraProps = {};

      extraProps = {
        TransitionProps: {
          ...props?.TransitionProps,
          onExited: handleExited,
        },
      };

      return (
        <Component
          {...props}
          key={id}
          onClose={handleClose}
          {...(options && !options.destroyOnClose && extraProps)}
        />
      );
    });

  return (
    <ModalContext.Provider
      value={{
        state,
        hideModal: hide,
        destroyModal: destroy,
        showModal: show,
        destroyModalsByRootId: destroyByRootId,
      }}>
      {children}
      {renderState()}
    </ModalContext.Provider>
  );
}
