
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React, { useEffect, useState } from 'react';
import { SessionProvider } from 'next-auth/react';
import Head from 'next/head';
import { SnackbarProvider } from 'notistack';
// next-translate polyfill for older browsers
import 'intl-pluralrules';
// globalthis for some older/mobile browsers
import 'globalthis/polyfill';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AppCacheProvider } from '@mui/material-nextjs/v13-pagesRouter';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LicenseInfo } from '@mui/x-license';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { theme } from '../src/theme';
import Layout from '../src/components/layout';
import CustomSnackbar from '../src/components/snackbar';
import { APIContext } from '../src/utils/api';
import ModalProvider from '../src/modals/modal-provider';
// Fonts
import '../public/fonts/satoshi.css';
import '../src/components/credential-builder/credential-builder.css';
import '../src/global.css';
import { NotificationProvider } from '../src/hooks/notifications';
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY);
function MyApp(props) {
    const { Component, pageProps } = props;
    const [apiKey, setAPIKey] = useState(null);
    useEffect(() => {
        TimeAgo.addDefaultLocale(en);
    }, []);
    return (<AppCacheProvider options={{ enableCssLayer: true }} {...props}>
      <Head>
        <title>Dock Certs</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width"/>
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SessionProvider session={pageProps.session}>
          <SnackbarProvider autoHideDuration={2000} anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }} content={(key, message) => (<div>
                <CustomSnackbar id={key} {...message}/>
              </div>)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <APIContext.Provider value={{ apiKey, setAPIKey }}>
                <ModalProvider>
                  <NotificationProvider>
                    <Layout isTestmode={pageProps.isTestmode}>
                      <Component {...pageProps}/>
                    </Layout>
                  </NotificationProvider>
                </ModalProvider>
              </APIContext.Provider>
            </LocalizationProvider>
          </SnackbarProvider>
        </SessionProvider>
      </ThemeProvider>
    </AppCacheProvider>);
}

    export default __appWithI18n(MyApp, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  