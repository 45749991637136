import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import {
  NOTIFICATION_NEW_TEAM_MEMBER,
  NOTIFICATION_TRUST_REGISTRY_INVITE,
  NOTIFICATION_TRUST_REGISTRY_UPDATED,
  NOTIFICATION_TRUST_REGISTRY_NEW_PARTICIPANT,
  NOTIFICATION_TRUST_REGISTRY_PARTICIPANT_SUSPENDED,
  NOTIFICATION_TRUST_REGISTRY_PARTICIPANT_REACTIVATED,
  NOTIFICATION_TRUST_REGISTRY_PARTICIPANT_LEFT,
} from 'dock-shared/src/notification-types';

// TODO: eventually switch to Intl.RelativeTimeFormat
// using this library for compatibility reasons atm
import ReactTimeAgo from 'react-time-ago';

import useApi from '../../utils/api';

function getPrimaryActionsProps(t, type, data) {
  switch (type) {
    case NOTIFICATION_TRUST_REGISTRY_INVITE:
      return {
        variant: 'text',
        href: `/ecosystems/join-ecosystem?token=${data.inviteToken}`,
        children: t('notifications:join-ecosystem'),
      };
    case NOTIFICATION_TRUST_REGISTRY_UPDATED:
    case NOTIFICATION_TRUST_REGISTRY_NEW_PARTICIPANT:
    case NOTIFICATION_TRUST_REGISTRY_PARTICIPANT_SUSPENDED:
    case NOTIFICATION_TRUST_REGISTRY_PARTICIPANT_REACTIVATED:
    case NOTIFICATION_TRUST_REGISTRY_PARTICIPANT_LEFT:
      return {
        variant: 'text',
        href: `/ecosystems/${data.registryId}`,
        children: null,
      };

    case NOTIFICATION_NEW_TEAM_MEMBER:
      return {
        variant: 'text',
        href: '/settings/team',
        children: null,
      };
    default:
      return {};
  }
}

const NotificationActions = ({ type, data }) => {
  const { t } = useTranslation(['notifications', 'common']);
  const { apiPost: apiPostProd } = useApi(true);
  const showActions = type === NOTIFICATION_TRUST_REGISTRY_INVITE && data?.inviteToken;

  if (!showActions) {
    return null;
  }

  const getSecondaryActionsProps = () => {
    switch (type) {
      case NOTIFICATION_TRUST_REGISTRY_INVITE:
        return {
          component: 'button',
          onClick: () =>
            apiPostProd('trust-registries/invitations/decline', {}, { token: data.inviteToken }),
          children: t('common:decline'),
          color: 'secondary',
        };
      default:
        return {};
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
      <Link {...getPrimaryActionsProps(t, type, data)} dense sx={{ textDecoration: 'none' }} />
      <Link {...getSecondaryActionsProps()} dense sx={{ textDecoration: 'none' }} />
    </Box>
  );
};

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export default function Notification({ type, data, created }) {
  const { t } = useTranslation('notifications');
  const title = t(`notifications:${type}-title`, data);
  const message = t(`notifications:${type}-message`, data);
  const date = new Date(created);
  const { href } = getPrimaryActionsProps(t, type, data) || {};
  const router = useRouter();

  function gotoLink() {
    router.push(href);
  }

  return (
    <>
      <ListItem
        alignItems="flex-start"
        onClick={href && gotoLink}
        sx={href && { cursor: 'pointer' }}>
        <ListItemIcon sx={{ marginTop: '6px' }}>
          <Avatar
            src={data.logoUrl}
            alt={title}
            sx={{
              width: '40px',
              height: '40px',
              backgroundColor: stringToColor(title),
            }}>
            {title.substr(0, 1)}
          </Avatar>
        </ListItemIcon>
        <ListItemText
          sx={{ marginTop: 0 }}
          primary={
            <Typography
              variant="h6"
              sx={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
              }}>
              {title}
            </Typography>
          }
          secondary={
            <React.Fragment>
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  color: '#1A1A1E',
                }}>
                {message}
              </span>

              <Typography
                sx={{ display: 'block', marginTop: '1px', fontSize: 12, color: '#51525C' }}
                component="span"
                variant="body2"
                color="text.primary">
                <ReactTimeAgo date={date} locale="en-US" />
              </Typography>
              <NotificationActions type={type} data={data} />
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider component="li" />
    </>
  );
}
