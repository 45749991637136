export function toTimestamp(dateStr) {
  return Math.floor(new Date(dateStr).getTime() / 1000);
}

export function getUserAttributes(user) {
  const baseAttributes = {
    id: user.sub,
    email: user.email,
    last_login: user.last_login ? toTimestamp(user.last_login) : undefined,
    created_at: user.created_at ? toTimestamp(user.created_at) : undefined,
    last_call: user.last_call ? toTimestamp(user.last_call) : undefined,
    root_user_id: user.root_user_id,
    total_requests: user.total_requests,
    total_test_txs: user.total_test_txs,
    total_prod_txs: user.total_prod_txs,
  };

  if (!user.name) {
    return baseAttributes;
  }

  const nameSplit = user.name.split(' ');
  return {
    ...baseAttributes,
    call_limit: user.call_limit,
    used_calls: user.used_calls,
    status: user.status,
    first_name: nameSplit[0],
    last_name: nameSplit[nameSplit.length - 1],
    plan_name: user.subscription ? user.subscription.product_id : 'free',
    subscription_status: user.subscription ? user.subscription.status : undefined,
  };
}
