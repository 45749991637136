import React from 'react';
import { useSnackbar } from 'notistack';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '../../public/icons/warning.svg';
import InfoIcon from '../../public/icons/infoicon.svg';

const icons = {
  success: <CheckCircleOutlineOutlinedIcon style={{ color: '#34D399' }} />,
  info: <InfoIcon />,
  error: <CancelOutlinedIcon style={{ color: '#F87171' }} />,
  warning: <WarningIcon />,
};

export default function SnackBar(props) {
  const { variant, msg, key } = props;
  const { closeSnackbar } = useSnackbar();

  function handleClose() {
    closeSnackbar(key);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '16px',
        width: '100vw',
        maxWidth: '360px',
        minHeight: '52px',
        boxSizing: 'border-box',
        background: '#FFFFFF',
        boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
      }}>
      <div
        style={{
          marginRight: '15px',
          height: '24px',
          width: '24px',
        }}>
        {icons[variant] || icons.success}
      </div>
      <div
        style={{
          margin: '0 0',
          paddingTop: '2px',
          width: '100%',
        }}>
        <strong style={{ color: '#18181B' }}>{msg && (msg.title || msg)}</strong>
        {msg.text && (
          <>
            <br />
            {msg.text}
          </>
        )}
      </div>
      <div
        onClick={handleClose}
        style={{
          marginLeft: 'auto',
          height: '24px',
          width: '24px',
          cursor: 'pointer',
        }}>
        <CloseIcon style={{ color: '#A1A1AA' }} />
      </div>
    </div>
  );
}
