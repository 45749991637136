export const NOTIFICATION_NEW_TEAM_MEMBER = 'new_team_member';
export const NOTIFICATION_TRUST_REGISTRY_INVITE = 'trust_registry_invite';
export const NOTIFICATION_TRUST_REGISTRY_UPDATED = 'trust_registry_updated';
export const NOTIFICATION_TRUST_REGISTRY_DELETED = 'trust_registry_deleted';
export const NOTIFICATION_TRUST_REGISTRY_NEW_PARTICIPANT = 'trust_registry_new_participant';
export const NOTIFICATION_TRUST_REGISTRY_PARTICIPANT_REMOVED = 'trust_registry_participant_removed';
export const NOTIFICATION_TRUST_REGISTRY_PARTICIPANT_LEFT = 'trust_registry_participant_left';
export const NOTIFICATION_TRUST_REGISTRY_PARTICIPANT_SUSPENDED =
  'trust_registry_participant_suspended';
export const NOTIFICATION_TRUST_REGISTRY_PARTICIPANT_REACTIVATED =
  'trust_registry_participant_reactivated';
export const NOTIFICATION_TRUST_REGISTRY_PARTICIPANT_UPDATED = 'trust_registry_participant_updated';
