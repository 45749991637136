import { createContext } from 'react';
import { initialState } from './reducer';

export const initialContextState = {
  state: initialState,
  hideModal: () => {},
  showModal: () => ({
    id: 'id',
    hide: () => {},
    destroy: () => {},
    update: () => {},
  }),
  destroyModal: () => {},
  destroyModalsByRootId: () => {},
};

const ModalContext = createContext(initialContextState);

export default ModalContext;
