import { useSnackbar as useSnackbarO } from 'notistack';

export const useSnackbarOriginal = useSnackbarO;

const READING_SPEED = 225; // Reading speed in words per minute
const AUTOHIDE_MIN = 2; // Minimum autohide time in seconds
const AUTOHIDE_MAX = 15; // Maximum autohide time in seconds
const COMPREHENSION_MIN = 1; // Minimum comprehension time in seconds
const COMPREHENSION_MAX = 4; // Maximum comprehension time in seconds
const COMPREHENSION_FACTOR = 15; // Comprehension factor

export const calculateDisplayTimeInSeconds = (
  message,
  speed = READING_SPEED,
  minTime = AUTOHIDE_MIN,
  maxTime = AUTOHIDE_MAX,
  minComprehension = COMPREHENSION_MIN,
  maxComprehension = COMPREHENSION_MAX,
  comprehensionFactor = COMPREHENSION_FACTOR
) => {
  if (!message || typeof message !== 'string') {
    return AUTOHIDE_MIN;
  }

  try {
    const wordCount = message.split(/\s+/).length;
    const comprehension = Math.min(
      maxComprehension,
      Math.max(minComprehension, wordCount / comprehensionFactor)
    );
    const displayTime = (wordCount / speed) * 60 + comprehension;

    return Math.round(Math.min(maxTime, Math.max(minTime, displayTime)));
  } catch (error) {
    console.error(error);

    return AUTOHIDE_MIN;
  }
};

export const useSnackbar = () => {
  const originalProp = useSnackbarO();
  const enqueueSnackbar = (message, options) => {
    const msg = typeof message === 'object' ? message.message : message;
    if (msg) {
      const autoHideDuration = calculateDisplayTimeInSeconds(msg) * 1000;
      options = {
        autoHideDuration,
        ...options,
      };

      originalProp.enqueueSnackbar({ msg, ...options }, options);
    }
  };
  return { enqueueSnackbar, closeSnackbar: originalProp.closeSnackbar };
};
